

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Jeko', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Jeko-Regular';
  src: local('Jeko-Regular'), url('assets/fonts/Jeko/Jeko-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Jeko-Black';
  src: local('Jeko-Black'), url('assets/fonts/Jeko/Jeko-Black.ttf') format('truetype');
  font-weight: 400;
}

